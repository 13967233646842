@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=DynaPuff&family=Inter&display=swap');

#host-banner {
  background: var(--main);
  background: var(--main-gradient);
  box-shadow: var(--main-light) 0px 1px 20px 0px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.host-lobby {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.join-instruction-edge, .above-instructions, .below-instructions {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: stretch;
	justify-content: center;
	align-items: stretch;
}

.badge-holding {
	width: 15vw;
	flex-grow: 1;
	display: flex;
	align-items: center;
  justify-content: center;
}
