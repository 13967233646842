@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=DynaPuff&family=Inter&display=swap');

:root {
  --bg: #FFFFFF;
  --main-rgb: 255, 128, 0;
  --main: rgb(var(--main-rgb));
  --main-light: color-mix(in lch, var(--main) 50%, #FFFFFF);
  --main-super-light: color-mix(in lch, var(--main) 5%, #FFFFFF);
  --main-dark: color-mix(in lch, var(--main) 80%, #000000);
  --left-rgb: 255, 0, 170;
  --left-rgb-subtle: 255, 120, 170;
  --left: rgb(var(--left-rgb));
  --left-subtle: rgb(var(--left-rgb-subtle));
  --left-light: rgba(var(--left-rgb), 35%);
  --left-super-light: color-mix(in lch, var(--left) 5%, #FFFFFF);
  --left-dark: color-mix(in lch, var(--left) 80%, #000000);
  --right-rgb: 0, 255, 170;
  --right-rgb-subtle: 170, 255, 120;
  --right: rgb(var(--right-rgb));
  --right-subtle: rgb(var(--right-rgb-subtle));
  --right-light: rgba(var(--right-rgb), 35%);
  --right-super-light: color-mix(in lch, var(--right) 5%, #FFFFFF);
  --right-dark: color-mix(in lch, var(--right) 80%, #000000);
  --main-gradient: linear-gradient(45deg, var(--left) 0%, var(--main) 50%, var(--right) 100%);
  --main-gradient-rev: linear-gradient(45deg, var(--right-subtle) 0%, var(--main) 50%, var(--left-subtle) 100%);
  --main-gradient-light: linear-gradient(45deg, var(--left-light) 0%, var(--main-light) 50%, var(--right-light) 100%);
  --main-gradient-dark: linear-gradient(215deg, var(--left-dark) 0%, var(--main-dark) 50%, var(--right-dark) 100%);
  --title-font: "DynaPuff", sans-serif;
  --action-font: "Concert One", sans-serif;
  --legible-font: "Inter", sans-serif;
}

h1, h2, p {
  font-family: Inter, sans-serif;
  color: black;
}

html, body {
  height:100%;
  margin: 0;
}


body {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
  height: auto;
  width: auto;
}


/*mobile*/
@media (max-width: 767px) {
  .logo{
    height: 100%;
    width: 100%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .logohost{
    height: 70%;
    width: 70%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .questionnaireInputs{
    overflow-y: scroll;
    height: calc(100vh - (100vw * 0.1003) - 19.49px);
  }

  .form{
    width: 100%
  }

  .questionnaireInput{
    width: 95%;

  }

  .answerButton{
    align-self: center;
    width: 100%;
  }

  .idInput{
    padding-bottom: 5%;
  }

  .element{
    height: 78%;
    margin: 0px;
  }

  div.about-body {
    font-size: 1.1em;
  }
}

/* Styles for desktop screens */
@media (min-width: 768px) {
  .logo{
    height: 70%;
    width: 70%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .logohost{
    height: 30%;
    width: 30%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .questionnaireInputs{
    overflow-y: scroll;
    height: calc(100vh - (100vw * 0.07511) - 18.76px);
  }

  .form{
    width: 30%;
  }

  .questionnaireInput{
    width: 30%;
  }

  .answerButton{
    align-self: center;
    width: 50%;
  }

  .idInput{
    padding-bottom: 5%;
    width: 30%;
  }

  .element{
    height: 64%;
    margin: 0px;
    max-width: 500px;
    margin: 0 auto;
  }
}

.answerOptions{
  align-self: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
}

.answerButton{
  align-self: center;
  width: 50%;
}


.joinForm{
  align-self: center;
  align-items: center;
  justify-content: center;
  display: grid;
  margin: 2.5% auto;
  margin-left: 10%;
  margin-right: 10%;
}

.about {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff10;
}

.about-text {
  padding: 10px;
}

.friendivia {
  font-family: "Concert One", sans-serif;
  font-size: 1.4em;
  background: var(--main);
  background: var(--main-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-text h1 {
  font-size: 2em;
  font-family: "Concert One", sans-serif;
}

.about-body {
  margin: 0 auto;
  max-width: 800px;
  font-size: 1.5em;
  text-align: center;
}

.banner-text {
  font-size: 4.5em;
  color: white;
  font-family: var(--title-font);
  text-shadow: 0px 0px 10px #FFFFFF80;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
  margin: 0;
}

.bottom-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}

.hostFormat{
  background-repeat: repeat;
  background-size: 60% auto;
  background-clip: cover;
  size: 100%;
  justify-content: center;
  text-align: center;
}

.host-logo-img {
  margin-top: 30px;
}

.questionnaireInput{
  display: flex;
}

.body{
  /*make */
  font-family: Inter, sans-serif;
  text-align: center;
  margin: 5% auto;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 1em;
}
/**/
.button{
  background-color: var(--accent);
  border: none;
  color: var(--bg);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 20px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 5px 10px;
}

.noBtnElement{
  height: 100%;
  margin: 0px;
}

.bottomContainerHost{
  background: rgb(229,184,237);
  background: linear-gradient(180deg, rgba(229,184,237,1) 0%, rgba(191,120,213,1) 100%);
  display: flex;
  justify-content: flex-start;
  padding-top: 200px;
  padding-left: 30px;
}

.lobby-bottom-bar Button {
  margin: 10px;
}

.joined-players {
  height: 100%;
}

.host-content {
  text-align: center;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.clearDataButton{
  text-align: left;
  height: 100%;
}

.LobbySettings{
  margin-left: 100px;
  background-color: #5e00ff;
}

.LobbyAbout{
  margin-top: 10px;
  background-color: #5e00ff;
}

.musicIcon{
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 25px;
  background-color: white;
}


.bottomContainer{
  display: flex;
  justify-content: flex-start;
}

.guesses{
  margin: auto;
  padding: 0%;
}

.join-instructions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.join-instruction-edge {
  width: 35vw;
  height: 100%;
}

.gameid p.id {
  font-weight: 1000;
  font-size: 7em;
}

.gameid p.label {
  margin: 0;
}

.horizontal{
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

.players{
  font-family: Inter, sans-serif;
  font-size: 2em;
  margin:auto;
  text-align: center;
}

.player{
  font-family: Inter, sans-serif;
  font-size: 1em;
  margin:auto;
  text-align: center;
  padding: 10px;
  color:var(--text);
  background: var(--bg);
  border-radius: 5px;
  font-weight: 100;
}

.player:hover{
  text-decoration: line-through;
}

.ul{
  list-style-type: none;
  justify-content: center;
  justify-self: center;
  display: flex;
  flex-direction: column; 
  flex-wrap: wrap;
  padding-left: 0;
}

.answer{
  align-self: center;
  width: 50%;
  font-family: Inter, sans-serif;
  font-size: 1.5em;
  margin:auto;
  text-align: center;
}

.paper{
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 50%;
  align-self: center;
  text-align: center;
}

.waiting{
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 5% auto;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.donePlayers{
  width: 20vw;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 5% auto;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.waitingPlayers{
  width: 20vw;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: 5% auto;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.playerbox{
  width: 20vw;
  align-self: center;
  margin-left: 5vw;
  margin-right: 5vw;
}

.lobby_player{
  margin-left: 1vw;
  margin-right: 1vw;
}

.align_center {
  text-align: center;
}


.player_join {
  text-align: center;
  height: 100%;
}

.banner {
  background: var(--main);
  background: var(--main-gradient);
  box-shadow: var(--main-light) 0px 1px 20px 0px;
  margin-bottom: 1vw;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: -1px;
}

.player-banner-text {
  font-size: 2.5em;
}

.bannerEdge {
  width: 15vw;
}

.id{
  margin:0;
}

.hostAnswerScroll{
  overflow-y: auto;
  height: calc(100vh - (4.5em + 50px));
}

.participant{
  font-family: Inter, sans-serif;
  font-size: 1.5em;
  font-weight: bolder;
  margin: 1vw;
  text-align: center;
  padding: 10px;
  color:var(--bg);
  background: var(--accent);
  border-radius: 5px;
  width: 20vw;
  display: flex;
  justify-content: center;
}

.ol{
  list-style-type: none;
  margin: 0;
}

.li{
  list-style-type: none;
  margin: 0;

}

.ol li{
  list-style-type: none;
  margin: 0;

}

.score {
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 1.2em;
  margin: auto;
  text-align: center;
  padding: 10px;
  color: var(--bg);
  width: 5vw;
  background: var(--accent);
  border-radius: 5px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Inter";
  color:var(--bg);
  font-weight: bolder;
  font-size: 3vw;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  margin-top: 3vh;
}

.timeNumber{
  padding-top: 22%;
}

.dot{
  justify-content: center;
  align-items: center;
  height: 7vw;
  width: 7vw;
  background-color: var(--main);
  border-radius: 50%;
  margin-top: 50%;
  margin: auto;
  margin-bottom: 1vw;
  align-self: center;
}

.question{
  font-size: 1.5em;
  margin: auto;
  margin-bottom: 1vw;
}

.showAnswer{
  font-size: 1.5em;
  margin: auto;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

.leaderboard{
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.correct{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1em;
  margin:auto;
  text-align: center;
  padding: 10px;
  color:var(--bg);
  border-radius: 5px;
  height: 100%;
  vertical-align: middle;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}

.wait{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1em;
  text-align: center;
  padding: 0px;
  color:var(--bg);
  vertical-align: middle;
  margin: 0px;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
}

.incorrect{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1em;
  margin:auto;
  text-align: center;
  padding: 10px;
  color:var(--bg);
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%);
}

.correctImg{
  width: auto;
  height: auto;
  align-self: center;
  -webkit-animation:grow 2s ease-in-out infinite;
  -moz-animation:grow 2s ease-in-out infinite;
  animation:grow 2s ease-in-out infinite;
  margin-top: -8vh;
}

.correctTxt{
  font-family: Inter, sans-serif;
  color:var(--bg);
  font-size: 2.5em;
  font-weight: bold;
}

.waitTxt{
  font-family: Inter, sans-serif;
  color:var(--bg);
  font-size: 1.5em;
  padding-left: 10vw;
  padding-right: 10vw;
  font-weight: bold;
}

.subjectTxt{
  font-family: Inter, sans-serif;
  color:var(--bg);
  font-size: 2.5em;
  font-weight: bold;
}

.subject{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1em;
  margin:auto;
  text-align: center;
  padding: 10px;
  color:var(--bg);
  width: 100%;
  height: 100%;
  vertical-align: middle;
  background-color: #bdd4e7;
  background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
}

.player-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: center;
  max-height: 44vh;
  max-width: 80vw;
  margin: 0 auto;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.player-list::-webkit-scrollbar { 
  display: none;
}

.lobby_player {
  flex: 0 0 auto;
  padding-left: 1vw;
  padding-right: 1vw;
  min-width: 100px;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.secretButton{
  background: transparent;
    border: none !important;
    font-size:0;
  margin-top: -40px !important;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.niceTryImg{
  width: auto;
  height: auto;
  align-self: center;
  -webkit-animation:grow 2s ease-in-out infinite;
  -moz-animation:grow 2s ease-in-out infinite;
  animation:grow 2s ease-in-out infinite;
  margin-top: -8vh;
}

@media (min-aspect-ratio: 1) {
  .eyesImg{
    height: 50vh;
    width: auto;
    margin-top: -8vh;
    -webkit-animation:bounce 4s ease-in-out infinite;
    -moz-animation:bounce 4s ease-in-out infinite;
    animation:bounce 4s ease-in-out infinite;
  }

  .hourglass {
    width: auto;
    height: 50vh;
    margin: auto;
    margin-top: -18vh;
    margin-bottom: -10px;
    -webkit-animation:spin 10s ease-in-out infinite;
    -moz-animation:spin 10s ease-in-out infinite;
    animation:spin 10s ease-in-out infinite;
  }
  
  @keyframes grow {
    0% {
      height: 50vh;
    }
    25% {
      height: 55vh;
    }
    50% {
      height: 50vh;
    }
    100%{
      height: 50vh;
    }
  }
}

@media (max-aspect-ratio: 1) {
  .eyesImg{
    width: 100vw;
    height: auto;
    margin-top: -8vh;
    -webkit-animation:bounce 4s ease-in-out infinite;
    -moz-animation:bounce 4s ease-in-out infinite;
    animation:bounce 4s ease-in-out infinite;
  }

  .hourglass {
    width: 50vw;
    height: auto;
    margin: auto;
    margin-top: -18vh;
    margin-bottom: -10px;
    -webkit-animation:spin 10s ease-in-out infinite;
    -moz-animation:spin 10s ease-in-out infinite;
    animation:spin 10s ease-in-out infinite;
  }
  
  @keyframes grow {
    0% {
      height: 50vw;
    }
    25% {
      height: 55vw;
    }
    50% {
      height: 50vw;
    }
    100%{
      height: 50vw;
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
} 


.fillScreen{
  height: 100vh;
  overflow: hidden;
}

.vs{
  font-size: 8em;
  font-weight: bolder;
  background-image: linear-gradient(45deg, #f3ec78, #af4261);
  background-clip: text;
}

.tie{
  font-size: 1.5em;
}

.musicIcon{
  width: 5vh;
}

.scroll{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: stretch;
}

.scrollEnabled{
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: space-between;
  align-items: stretch;
}

.css-k4qjio-MuiFormHelperText-root {
  color: #d32f2f !important;
}

#fixScreen {
  height: 101vh !important;
}

.host-screen {
  background: var(--main-super-light);
}

.host-footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px;
}

.leaderboard > div:first-child > .MuiPaper-root {
  box-shadow: 0 0 10px var(--main-light);
}
